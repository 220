import * as React from "react"
import banner from "../images/galaxy-banner.jpg"
import { StaticImage } from "gatsby-plugin-image"

const Header = () => (
  <header>
    {/* <img className="img-fluid w-100" src={banner} alt="Galaxy Banner" /> */}
    <StaticImage
      src="../images/galaxy-banner.jpg"
      alt="Galaxy Banner"
      placeholder="blured"
      quality="100"
      layout="fullWidth"
      objectFit="cover"
    />
  </header>
)

export default Header
