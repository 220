import React, { useEffect } from "react"
import { FacebookShareButton } from "react-share"
import { Element, scroller } from "react-scroll"

import fbLogo from "../images/fb-icon.png"
import instaLogo from "../images/instagram-icon.png"
import Header from "./header"

const ClaimPage = props => {
  useEffect(() => {
    // scroller.scrollTo("sectionElement", {
    //   duration: 1000,
    //   delay: 500,
    //   offset: -100,
    //   smooth: true,
    // })
    let height

    if (height !== document.getElementById("container").offsetHeight) {
      height = document.getElementById("container").offsetHeight
      window.parent.postMessage(
        {
          frameHeight: height,
        },
        "*"
      )
      console.log(height) // check the message is being sent correctly
    }
    const target = document.getElementsByTagName("main")[0]
    target.scrollIntoView()
  }, [])
  const socialURL =
    "https://www.galaxychocolate.co.uk/sites/g/files/fnmzdf211/files/2022-07/Galaxy_Purpose_Gift_a_Bar.gif"

  return (
    <div id="container">
      <Header />
      <section className="text-center py-5">
        <div className="container px-md-5">
          <Element name="sectionElement"></Element>
          {props.message === null ? (
            <h4 className="m-lg-5 my-4">
              Thanks for gifting a free bar. We have sent an{" "}
              <span className="text-pink">email</span> for you to forward to a
              deserving friend.
            </h4>
          ) : (
            <h4 className="m-lg-5 my-4">{props.message}</h4>
          )}

          <p className="bold-content mx-lg-5 px-lg-3">
            You can also <span className="text-pink">show support</span> for
            cocoa communities by sharing their story.
          </p>
          <div className="d-flex justify-content-center align-items-center my-md-5 my-4">
            <FacebookShareButton url={socialURL} hashtag="#OnePieceAtATime">
              <figure className="fb-icon">
                <img
                  className="img-fluid mh-100 rounded-0"
                  src={fbLogo}
                  alt="Facebook Logo"
                />
              </figure>
            </FacebookShareButton>
            {/* <figure className="insta-icon">
            <img
              className="img-fluid mh-100 ps-4"
              src={instaLogo}
              alt="Instagram Logo"
            />
          </figure> */}
          </div>
        </div>
      </section>
    </div>
  )
}
export default ClaimPage
